<script>
export default {
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  computed: {
    text() {
      return this.block.fields.Text;
    },
  },
};
</script>

<template>
  <div class="_textBlock-container">
    <div class="_textBlock-text" v-html="text">
    </div>
  </div>
</template>

<style>
._textBlock-container {
    padding: 0 0.5rem;

    @media(--tabletAndDesktop) {
        padding: 0;
    }
}

._textBlock-text {
    max-width: calc(600px);
    margin: auto;
}
</style>
